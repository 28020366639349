<!-- 用户列表 -->
<template>
  <section class="cont srcm-userList-box">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>数据中台</el-breadcrumb-item>
        <el-breadcrumb-item>SCRM</el-breadcrumb-item>
        <el-breadcrumb-item>{{ member_user === 'user_list' ? '用户列表' : '会员管理' }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-box">
      <!-- 查询框 -->
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class='search-item'>
            <label>姓名：</label>
            <el-input class="width-220" placeholder="请输入姓名" v-model="realName" clearable></el-input>
          </el-row>
          <el-row class="search-item">
            <label>手机号：</label>
            <el-input class="width-220" placeholder="请输入手机号" v-model="mobile" clearable></el-input>
          </el-row>
          <el-row class="search-item">
            <label>证件号：</label>
            <el-input class="width-220" placeholder="请输入证件号" v-model="certificateNo" clearable></el-input>
          </el-row>
          <el-row class="search-item">
            <label>ID号：</label>
            <el-input class="width-220" placeholder="请输入ID" v-model="id" clearable></el-input>
          </el-row>
        </el-row>
        <el-row class="search-row">
          <template v-if="member_user === 'user_list'">
            <el-row class="search-item">
              <label>昵称：</label>
              <el-input class="width-220" placeholder="请输入昵称" v-model="nickName" clearable></el-input>
            </el-row>
          </template>
          <el-row class="search-item" v-else>
            <label>会员等级：</label>
            <el-select class="width-220" placeholder="请选择" v-model="level">
              <el-option v-for="item in medalList" :key="item.id" :label="item.medalName" :value="item.medalCode"></el-option>
            </el-select>
          </el-row>
          <el-button class="bg-gradient" @click="getList('search')">搜索</el-button>
          <el-button class="bg-gradient" @click="resetEvent">重置</el-button>
          <!--               <el-button class="bg-gradient" @click="Printing">打印</el-button>-->
          <el-button class="bg-gradient" type="primary" v-if="member_user === 'user_list'" @click="addEditEvent('create')"><span>创建用户</span></el-button>       
          <el-button class="bg-gradient" type="primary" v-if="member_user === 'user_list'" @click="dialogImportUser = true"><span>导入用户</span></el-button>         
          <el-button style="display: none;" class="bg-gradient" type="primary" v-if="member_user === 'user_list'" @click="loadMember('create')"><span>拉取粉丝</span></el-button>
        </el-row>
      </el-row>
      <!-- 主体内容  -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn" v-if="member_user === 'user_list'">
          <el-row style="width: 500px;">
            <el-tabs v-model="tabState" @tab-click="getList">
              <el-tab-pane label="全部" name=" "></el-tab-pane>
              <el-tab-pane label="微信小程序" name="WECHAT_APPLET"></el-tab-pane>
              <el-tab-pane label="微信公众号" name="WECHAT_OFFICIAL"></el-tab-pane>
              <el-tab-pane label="抖音" name="DOUYIN_PLATFORM"></el-tab-pane>
              <el-tab-pane label="PMS" name="PMS"></el-tab-pane>
            </el-tabs>
          </el-row>
        </div>
        <!--            <div ref="print" id="print">-->
        <el-table v-loading="loading" :data="tableData" border fit stripe style="width: 100%" :header-cell-style="{background: '#f5f8f9'}">
          <el-table-column label="头像" key="1" align="center" width="60" v-if="checkList.indexOf('headPortrait') !='-1'">
            <template slot-scope="scope">
              <img v-if="scope.row.headPortrait" style="height: 35px;width: 35px;border-radius: 50%"
                   :src="scope.row.headPortrait" alt="">
              <img v-else style="height: 35px;width: 35px;border-radius: 50%"
                   src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="id" key="2" label="ID" width="170" v-if="checkList.indexOf('id') !='-1'"></el-table-column>
          <el-table-column prop="nickName" key="3" label="昵称" width="120" show-overflow-tooltip v-if="checkList.indexOf('nickName') !='-1'"></el-table-column>
          <el-table-column key="4" label="姓名" width="100" show-overflow-tooltip v-if="checkList.indexOf('realName') !='-1'">
            <template slot-scope="scope">
              {{ scope.row.realName || '-' }}
            </template>
          </el-table-column>
          <el-table-column prop="mobile" key="5" label="手机号" width="120" v-if="checkList.indexOf('mobile') !='-1'"></el-table-column>

          <template v-if="member_user === 'user_list'">
            <el-table-column key="6" label="证件号" width="170" v-if="checkList.indexOf('certificateNo') !='-1'">
              <template slot-scope="scope">
                {{ scope.row.certificateNo || '-' }}
              </template>
            </el-table-column>
            <el-table-column key="7" label="籍贯" width="100" v-if="checkList.indexOf('city') !='-1'">
              <template slot-scope="scope">
                <span v-if="scope.row.city.indexOf(',') !== -1 ">{{ scope.row.city.substr(7, 10) || '-' }}</span>
                <span v-else>{{ scope.row.city || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="来源" key="8" width="120" v-if="checkList.indexOf('userSource') !='-1'">
              <template slot-scope="scope">
                {{ scope.row.userSource | filterUserSource }}
              </template>
            </el-table-column>
            <el-table-column key="9" label="行业" width="100" v-if="checkList.indexOf('industry') !='-1'">
              <template slot-scope="scope">
                {{ scope.row.industry || '-' }}
              </template>
            </el-table-column>
            <el-table-column label="公司名称" width="170" v-if="checkList.indexOf('companyName') !='-1'">
              <template slot-scope="scope">
                {{ scope.row.companyName || '-' }}
              </template>
            </el-table-column>
            <el-table-column label="地址" width="170" v-if="checkList.indexOf('address') !='-1'">
              <template slot-scope="scope">
                {{ scope.row.address || '-' }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="储值余额"></el-table-column>-->
          </template>
          <template v-else>
            <el-table-column prop="medalName" key="6" label="会员等级" width="100"></el-table-column>
            <el-table-column label="总积分" prop="integralTotal" key="7" width="100" sortable>
              <template slot-scope="scope">
                {{ parseInt(scope.row.integralTotal) || 0 }}
              </template>
            </el-table-column>
            <el-table-column label="剩余积分" prop="integral" key="8" width="120" sortable>
              <template slot-scope="scope">
                {{ scope.row.integral || 0 }}
              </template>
            </el-table-column>
            <el-table-column label="消费金额" key="9" width="120" sortable>
              <template slot-scope="scope">
                {{ scope.row.sales || 0 }}
              </template>
            </el-table-column>
            <el-table-column label="累计消费次数" key="10" width="150" sortable>
              <template slot-scope="scope">
                  {{ !scope.row.hasOwnProperty('members') ? 0 : !scope.row.members.hasOwnProperty('consumptionCount') ? 0 : scope.row.members.consumptionCount }}
              </template>
            </el-table-column>
            <el-table-column label="上次的消费时间" key="11" width="170">
              <template slot-scope="scope">
                  {{ !scope.row.hasOwnProperty('members') ? '-' : !scope.row.members.hasOwnProperty('consumptionTime') ? '-' : scope.row.members.consumptionTime }}
              </template>
            </el-table-column>
            <el-table-column label="创建时间" key="12" sortable width="170">
              <template slot-scope="scope">
                  {{ scope.row.createTime || 0 }}
              </template>
            </el-table-column>
            <el-table-column label="储值卡" key="13" sortable  width="100">
              <template slot-scope="scope">
                {{ scope.row.amount || 0 }}
              </template>
            </el-table-column>
          </template>
          <el-table-column label="信息跟踪" key="14 || 13" :width="member_user !== 'user_list' ? '230' : '150'" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="getmemberAction(scope.row.id,'行为跟踪',scope.row)">行为跟踪</el-button>
              <el-button type="text" @click="getmemberAction(scope.row.id,'营销跟踪',scope.row)">营销跟踪</el-button>
              <el-button type="text" v-if="member_user !== 'user_list'" @click="getmemberAction(scope.row.id,'消费跟踪',scope.row)">消费跟踪</el-button>
            </template>
          </el-table-column>
          <el-table-column min-width="140" key="15 || 14" fixed="right">
            <template slot="header">
              <span>操作</span>
              <span class="float-r" v-if="member_user === 'user_list'">
                <i class="el-icon-s-tools" @click="customConfiguration(true)"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <el-button type="text" @click="checkEvent(scope.row)">详情</el-button>
              <el-button type="text" @click="addEditEvent('edit',scope.row)" v-if="member_user === 'user_list'">编辑</el-button>
              <el-button type="text" style="color:red;" @click="delEvent(scope.row.id)" v-if="member_user === 'user_list'">删除</el-button>
              <el-button type="text" @click="inComeEvnet(scope.row.id)" v-if="member_user !== 'user_list'">积分明细</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
        <!--            </div>-->
      </el-row>
    </el-row>

    <!--轨迹弹窗-->
    <el-dialog :title="tracktype" :visible.sync="trackdialog" width="52%">
      <!-- 轨迹表格 -->
      <el-table :data="tracktableData" border fit height="480px" style="width: 100%;overflow: auto;" stripe :header-cell-style="{ background: '#f5f8f9' }">
        <template v-if="tracktype === '行为跟踪' ">
          <el-table-column prop="medalName" label="行为">
            <template slot-scope="scope">
              {{ scope.row.source | filtertrack }}
            </template>
          </el-table-column>
          <el-table-column label="时间" prop="operateTime"/>
        </template>
        <template v-if="tracktype === '营销跟踪' ">
          <el-table-column prop="mainTitle" label="营销类型"/>
          <el-table-column prop="buyingTime" label="时间"/>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.hasOwnProperty('coverPath')">{{ scope.row.state | circlingFilter(that) }}</span>
              <span v-else>{{ scope.row.state | discountsFilter(that) }}</span>
            </template>
          </el-table-column>
        </template>
        <template v-if="tracktype === '消费跟踪' ">
          <el-table-column key="1" prop="orderNo" label="订单编号"/>
          <el-table-column key="2" label="成交时间" prop="payTime">
            <template slot-scope="scope">
              {{ scope.row.payTime || scope.row.updateTime }}
            </template>
          </el-table-column>
          <el-table-column key="3" label="订单金额" prop="orderTotalPrice"/>
          <el-table-column key="4" label="实际支付" prop="actualAmount"/>
          <el-table-column key="5" label="支付方式" prop="paymentMethod">
            <template slot-scope="scope">
              {{ scope.row.paymentMethod | payFilter(that) }}
            </template>
          </el-table-column>
          <el-table-column key="6" label="储值卡余额" prop="paymentMethod">
            <template slot-scope="scope">
              {{ scope.row.balance || '-' }}
            </template>
          </el-table-column>
        </template>
      </el-table>
      <!-- 分页 -->
      <!-- <pagination :total="tracktotal" :page-size="tracklimit" @handleSizeChangeSub="Pop_pageChange"  @handleCurrentChangeSub="Pop_handlePaging"/>-->
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="trackdialog = false">关 闭</el-button>
      </div> -->
    </el-dialog>

    <!-- 创建/编辑 弹窗 -->
    <handle-dialog :dialogTitle="dialogTitle" :isShow="isShow" :editData="editData" @handleClose="handleClose" @Refresh="getList"></handle-dialog>

    <!-- 导入用户弹窗 -->
    <el-dialog title="导入用户" :visible.sync="dialogImportUser" width="500px" top="30vh" v-loading="isImport" element-loading-text="导入模板中">
      <div class="import-user">
        <div class="import-box" @click="downloadTemp()">
          <img src="@/assets/download.png" alt="">
          <div>下载模板</div>
        </div>
        <el-upload class="upload-demo" ref="upload" action="#" :auto-upload="false" :show-file-list="false" :on-change="importTemp" :multiple="false">
            <div class="import-box">
            <img src="@/assets/import.png" alt="">
            <div>导入模板</div>
          </div>
        </el-upload>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogImportUser = false">关闭</el-button>
      </span> -->
    </el-dialog>

    <!-- 导入管理弹窗 -->
    <el-dialog title="导入管理" :visible.sync="dialogImportManagement" width="80%" :lock-scroll="true" :close-on-click-modal="false">
      <el-tabs value="success" style="width:96%;margin:0 auto">
        <el-tab-pane name="success">
          <span slot="label" style="font-size:16px">成功（{{successData.length}}）</span>
          <el-table :data="dataPage('success')">
            <el-table-column label="选择" width="60">
              <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked"></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop="mobile" label="手机号" width="120"></el-table-column>
            <el-table-column prop="realName" label="姓名" width="200"></el-table-column>
            <el-table-column prop="certificateNo" label="证件号"></el-table-column>
            <!-- <el-table-column prop="place" label="籍贯" width="100"></el-table-column> -->
            <el-table-column prop="companyName" label="公司名称"></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
            <el-table-column prop="industry" label="行业"></el-table-column>
            <el-table-column label="来源">
              <template slot-scope="scope">
                {{scope.row.source | filterUserSource}}
              </template>
            </el-table-column>
          </el-table>
            <!-- 分页 -->
          <div v-if="successData.length">
            <pagination :total="successData.length" :page-size="successLimit" @handleSizeChangeSub="pageChange($event, 'success')"  @handleCurrentChangeSub="handlePaging($event, 'success')"/>
            <el-button type="primary" @click="allSelect()">全选</el-button>
            <el-link type="primary" style="margin-left:20px" @click="dialogAllSelect = true">批量选择来源</el-link>
            <el-link type="primary" style="margin-left:20px" @click="deleteImportData()">删除</el-link>
          </div>
        </el-tab-pane>

        <el-tab-pane name="fail">
          <span slot="label" style="font-size:16px">失败（{{failData.length}}）</span>
            <el-table :data="dataPage('fail')">
            <el-table-column prop="mobile" label="手机号" width="120"></el-table-column>
            <el-table-column prop="realName" label="姓名" width="200"></el-table-column>
            <el-table-column prop="certificateNo" label="证件号"></el-table-column>
            <!-- <el-table-column prop="place" label="籍贯" width="100"></el-table-column> -->
            <el-table-column prop="companyName" label="公司名称"></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
            <el-table-column prop="industry" label="行业"></el-table-column>
            <el-table-column label="来源">
              <template slot-scope="scope">
                {{scope.row.source | filterUserSource}}
              </template>
            </el-table-column>
            <el-table-column prop="reason" label="失败原因"></el-table-column>
          </el-table>
            <!-- 分页 -->
          <pagination v-if="failData.length" :total="failData.length" :page-size="failLimit" @handleSizeChangeSub="pageChange($event, 'fail')"  @handleCurrentChangeSub="handlePaging($event, 'fail')"/>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogImportManagement = false;dialogImportUser = false" :disabled="importBtnLoading">取消导入</el-button>
        <el-button type="primary" @click="importUserData()" :loading="importBtnLoading">确认导入</el-button>
      </span>
    </el-dialog>

    <!-- 批量选择来源弹窗 -->
    <el-dialog title="批量选择渠道" :visible.sync="dialogAllSelect" width="500px" top="30vh">
      <div>
          <label>选择渠道：</label>
          <el-select v-model="source" placeholder="请选择">
            <el-option v-for="item in sourceList" :key="item" :label="item | filterUserSource" :value="item"></el-option>
          </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAllSelect = false">关闭</el-button>
        <el-button type="primary" @click="AllSelectSource()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 表头自定义弹窗 -->
    <el-dialog title="设置显示参数" :visible.sync="customShow" width="30%" top="30vh" @close="customConfiguration(false)">
      <el-checkbox-group v-model="checkList">
        <el-checkbox :label="item.name" class="m-bottom-20" v-for="item in tableHeaderList" :key="item.id" :disabled="item.required==='1'">{{ item.label }}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="customConfiguration(false)">取 消</el-button>
        <el-button type="primary" @click="saveConfiguration">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { urlObj } from '@/api/interface'
import { getDict, exportFile } from '@/common/js/common'
import HandleDialog from "@/components/local/userList/HandleDialog";
import { userDataTemplateDownload, markUserDataByExcel, importUserData, getTableHeader, saveTableHeader } from '@/api/data/memberManage/userList.js'

export default {
  name: "userList",
  components:{ HandleDialog },
  data() {
    return {
      that: this,
      member_user: "",       // 用户列表/会员管理
      tabState: ' ',
      pageshow: true,
      mobile: '',            // 手机号
      id: "",                // Id
      realName: '',          // 姓名
      certificateNo: "",     // 证件号
      nickName: "",          // 昵称
      level: "",             // 会员等级
      medalList: [],         // 会员等级列表
      loading: true,         // 表格加载效果
      tableData: [],         // 表格数据
      tracktableData: [],    // 轨迹数据
      isShow: false,         // 是否显示弹窗
      dialogTitle: "",       // 弹窗title
      editData:{},
      param: '',
      url: '',

      province: '',          // 省
      city: '',              // 市
      district: '',          // 区


      tracklimit: 10,        // 轨迹请求条数
      tracktotal: 0,         // 轨迹数据总条数
      trackdialog: false,    // 轨迹弹窗
      tracktype: '',         // 轨迹弹窗名字
      row_index: '',         // 点击row下标
      total: 0,              // 用户列表总条目数
      page: 1,               // 当前页 默认第一页
      limit: 0,              // 每页显示数
      Pop_total: 10,
      Pop_page: 1,
      Pop_limit: 0,

      dialogImportUser: false,       // 导入用户弹窗
      dialogImportManagement: false, // 导入管理弹窗
      dialogAllSelect: false,        // 批量选择渠道弹窗
      successData: [],               // 成功数据
      successLimit: 10,              // 成功数据请求条数
      successPage: 1,                // 成功数据当前页 默认第一页

      failData: [],                  // 失败数据
      failLimit: 10,                 // 失败数据请求条数
      failPage: 1,                   // 失败数据当前页 默认第一页

      source: '',                    // 导入用户来源
      sourceList: [],
      isImport: false,               // 导入数据加载
      importBtnLoading: false,       // 导入数据按钮

      customShow: false,             // 设置表头弹窗
      tableHeaderList:[],            // 表头列表
      checkList: [],                 // 多选数组

      del_comfirm: "是否确认删除该用户信息?将永远删除所有信息无法恢复！",
      del_success: '删除成功!',
      del_cancel: '已取消删除',
    }
  },
  computed: {
    ...mapState(['dictData', 'hotelInfo']),
    // 前端对数据分页
    dataPage() {
      return (type) => {
        if (type == 'success') {
          return this.successData.slice((this.successPage - 1) * 10, this.successLimit * this.successPage)
        }
        if (type == 'fail') {
          return this.failData.slice((this.failPage - 1) * 10, this.failLimit * this.failPage)
        }
      }
    }
  },
  watch: {
    $route(to) {
      this.pageshow = false
      this.page = 1
      this.$nextTick(() => {
        this.pageshow = true
      })
      if (to.name == 'userList') {
        this.member_user = this.$route.params.member_user
        this.level = ''
        this.mobile = ''
        this.realName = ''
        this.nickName = ''
        this.certificateNo = ''
        this.tabState = ' '
        this.getMedals()
        this.getList()
      }
    },
    hotelInfo: {
      handler(newVal, oldVal) {
        if (newVal.id !== oldVal.id && oldVal) {
          this.hotelId = this.hotelInfo.id
          this.getList()
          this.getMedals()
        }
      }
    }
  },
  mounted() {
    this.member_user = this.$route.params.member_user
    getDict(["certificate-type", 'order-status', 'payment-method', 'preSale-state', 'coupon-state'])
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getList()
    this.getMedals()
    this.customConfiguration(false)
  },
  methods: {
    // Printing () {
    //    this.$print(this.$refs.print)
    // },
    // 获取会员等级
    getMedals() {
      const url = urlObj.memberLevels
      const param = {hotelId: this.hotelInfo.id}
        if (JSON.parse(sessionStorage.getItem('hotelInfo')).accountType === 'HOTEL') {
          param.hotelId = this.hotelInfo.id
        }else {
          param.companyId = this.hotelInfo.storeId
        }
      this.$axios.post(url, param).then(res => {
        if (res.success) {
          this.medalList = res.records
          this.tableData.forEach(items => {
            this.medalList.forEach(item => {
              if (items.level == item.medalCode) {
                items.level = item.medalName
              }
            })
          })
        }
      })
    },
    //获取轨迹
    getmemberAction(id, type, row) {
      const hotelId = row.hotelId
      if (type == '行为跟踪') {
        this.url = urlObj.memberAction + `?limit=${this.tracklimit}&page=${this.Pop_page}`
        this.param = {
            companyId: this.hotelInfo.storeId,
            memberId:id,
            hotelId: hotelId
        }
      }
      if (type == '营销跟踪') {
        this.url = urlObj.getCouponListByUserId + `?limit=${this.tracklimit}&page=${this.Pop_page}`
        this.param = {
          memberId: id,
            hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId
        }
          if (JSON.parse(sessionStorage.getItem('hotelInfo')).accountType === 'HOTEL') {
            delete this.param.companyId
          }else {
            delete this.param.hotelId
          }
        this.$axios.post(this.url, this.param).then(res => {
          if (res.success) {
            this.loading = false
            this.tracktableData = res.records
            // this.tracktotal = res.tracktotal
            this.tracktableData.forEach(items => {
              this.medalList.forEach(item => {
                if (items.level === item.medalCode) {
                  items.level = item.medalName
                }
              })
            })
            this.tracktype = type
            this.trackdialog = true
          }
        })
        return
      }
      if (type === '消费跟踪') {
          console.log('消费跟踪')
        this.url = urlObj.getconsumeList + `?limit=${this.tracklimit}&page=${this.Pop_page}`
        this.param = {
          userId: id,
          hotelId: hotelId || this.hotelInfo.id,
          //  ||  this.hotelInfo.id
          // storeId: this.hotelInfo.storeId,
          orderStatusList: ["HAVE_PAID","CONFIRM",'CHECKIN','REFUNDED','TO_BE_SHIPPED','HAVE_TO_RETURN',
              'PENDING_RECEIPT','SUCCESSFUL_TRADE','STAY_EVALUATE','ALREADY_EVALUATE'],
        }
      }
      if (JSON.parse(sessionStorage.getItem('hotelInfo')).accountType === 'HOTEL') {
        delete this.param.companyId
      }else {
        if (type !== '消费跟踪') delete this.param.hotelId
      }
      this.$axios.post(this.url, this.param, 'json').then(res => {
        if (res.success) {
          this.loading = false
          this.tracktableData = res.records
          // this.tracktotal = res.tracktotal
          this.tracktableData.forEach(items => {
            this.medalList.forEach(item => {
              if (items.level == item.medalCode) {
                items.level = item.medalName
              }
            })
          })
          this.tracktype = type
          this.trackdialog = true
        }
      })
    },
    //获取表格数据
    getList(type) {
      if (type != undefined) {
        this.page = 1
      }
      const url = urlObj.membersPaging + `?limit=${this.limit}&page=${this.page}`
      let param = {
        level: this.level,
        id: this.id,
        mobile: this.mobile,
        realName: this.realName,
        keyword: this.nickName,
        certificateNo: this.certificateNo,
        userSource: this.tabState
      }
      if (JSON.parse(sessionStorage.getItem('userInfo')).accountType === 'HOTEL' || JSON.parse(sessionStorage.getItem('userInfo')).accountType === 'PLATFORM') {
        param.hotelId = this.hotelInfo.id
      }else {
        param.companyId = this.hotelInfo.storeId
      }
      if (this.member_user == 'user_list') {
        param.level = ''
      } else {
        this.level === '' ? param.level = 'user' : param.level = this.level
      }
      this.$axios.post(url, param, 'json').then(res => {
        if (res.success) {
          this.loading = false
          this.tableData = res.records
          this.total = res.total
          this.tableData.forEach(items => {
            this.medalList.forEach(item => {
              if (items.level == item.medalCode) {
                items.level = item.medalName
              }
            })
          })
        }
      })
    },

    //重置
    resetEvent() {
      this.id = ''
      this.mobile = ''
      this.realName = ''
      this.nickName = ''
      this.certificateNo = ""
      this.tabState = ' '
      this.level = ''
      this.limit = 10
      this.pageshow = false
      this.page = 1
      this.$nextTick(() => {
        this.pageshow = true
      })
      this.getList()
    },
    loadMember(){
      const url = '/event/wechatMember/importUser'
      this.$axios.post(url, { hotelId :this.hotelInfo.id }).then(res => {
        alert('接口调用完成')
      })
    },
    //创建/编辑
    addEditEvent(state, val) {
      // this.$ref.ruleForm.resetFields()
      this.isShow = true
      // if (this.$refs.hasOwnProperty('ruleForm')) this.$refs.ruleForm.resetFields()
      if (state === 'create') {
        this.dialogTitle = '创建'
      } else {
        this.dialogTitle = '编辑'
        this.editData = val
      }
    },

    // 关闭弹窗
    handleClose(bool) {
      this.isShow = bool
    },
    //详情
    checkEvent(row) {
      sessionStorage.setItem('onememberInfo', JSON.stringify(row))
      this.$router.push({
        path: '/user_check',
        query: {
          memberInfo: this.member_user,
        }
      })
    },
    //删除
    delEvent(id) {
      this.$confirm('确定移除?', '提示', {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        type: 'warning'
      }).then(() => {
        let ids = [];
        ids.push(id)
        const param = {ids: ids.toString()}
        const url = urlObj.delMember
        this.$axios.post(url, param).then(res => {
          if (res.success) {
            this.$message({
              showClose: true,
              message: this.del_success,
              type: 'success'
            })
            this.getList()
          }
        }).catch(error => {
          this.$message.error(error)
        })
      })
    },
    //收益明细
    inComeEvnet(id) {
      this.$router.push({path: '/income_details', query: {id}})

    },
    // 改变每页数
    pageChange(num, type) {
      if (type == 'success') {
        this.successLimit = num
      } else if (type == 'fail') {
        this.failLimit = num
      } else {
        this.limit = num
        this.getList()
      }
    },
    // 改变当前页
    handlePaging(num, type) {
      if (type == 'success') {
        this.successPage = num
      } else if (type == 'fail') {
        this.failPage = num
      } else {
        this.page = num
        this.getList()
      }
    },
      Pop_pageChange(num) {
      this.tracklimit = num
    },
      Pop_handlePaging(num) {
      this.Pop_page = num
    },
    
    // 下载模板
    downloadTemp() {
      userDataTemplateDownload().then(res => {
        exportFile(res, '用户数据模板.xlsx')
      })
    },

    // 文件是否为excel文件
    isExcel(file) {
      return /\.(xlsx)$/.test(file.name)
    },

    // 导入模板
    importTemp(file) {
      if (!this.isExcel(file)) {
        this.$message.error('只能上传.xlsx后缀的excel文件')
        return
      }
      this.isImport = true
      let multipartFile = new FormData()
      multipartFile.append("multipartFile", file.raw)
      multipartFile.append('hotelId', this.hotelInfo.id)
      markUserDataByExcel(multipartFile).then(res => {
        if (res.success) {
          this.successData = res.records.success
          this.failData = res.records.fail
          for (let i in this.successData) {
            this.$set(this.successData[i], 'checked', false)
            if (!this.sourceList.includes(this.successData[i].source)) {
              this.sourceList.push(this.successData[i].source)
            }
          }
          this.dialogImportManagement = true
        } else {
          this.$message.error('文件上传失败')
        }
        this.isImport = false
      })
    },

    // 全选
    allSelect() {
      let isChecked = this.successData.every(item => item.checked == true)
      this.successData = this.successData.map(item => {
        item.checked = !isChecked
        return item
      })
    },

    // 批量选择来源
    AllSelectSource() {
      this.successData = this.successData.map(item => {
        if (this.source == item.source) {
          item.checked = true
        }
        return item
      })
      this.dialogAllSelect = false
    },

    // 删除导入数据
    deleteImportData() {
      this.$confirm('删除后将无法导入进用户列表', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
          let isChecked = this.successData.every(item => item.checked == false)
          if (isChecked) {
            this.$message.error('请选择需要删除的用户')
          } else {
            this.successData = this.successData.filter(item => item.checked == false)
            this.$message.success('删除成功')
          }
      })
    },

    // 确认导入数据
    importUserData() {
      let data = this.successData.filter(item => item.checked == true)
      if (data.length == 0) {
        this.$message.error('请选择需要导入的数据')
        return
      }
      this.importBtnLoading = true
      importUserData(data).then(res => {
        if (res.success) {
          this.$message.success('导入成功')
          this.dialogImportManagement = false
          this.dialogImportUser = false
          this.importBtnLoading = false
          this.getList()
        }
      })
    },

    // 配置表头
    customConfiguration(bool){
      let data = {
        companyId: this.hotelInfo.storeId,
        model: "用户列表"
      } 
      getTableHeader(data).then(({success,records})=>{
        if (success) {
          if (records.length) {
            this.tableHeaderList = records
            this.checkList = []
            for (let i = 0; i < records.length; i++) {
              if (records[i].checked==='1') {
                this.checkList.push(records[i].name)
              }
            }
            this.customShow = bool
          } else {
            data.companyId = '0',
            getTableHeader(data).then(({success,records})=>{
              if (success) {
                this.tableHeaderList = records
                this.checkList = []
                for (let i = 0; i < records.length; i++) {
                  if (records[i].checked==='1') {
                    this.checkList.push(records[i].name)
                  }
                }
                this.customShow = bool
              }
            })
          }
        }
      })
    },

    // 保存配置
    saveConfiguration(){
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = {
        companyId: this.hotelInfo.storeId,
        hotelId: this.hotelInfo.id,
        list:[],
        model: "用户列表"
      }
      for (let i = 0; i < this.tableHeaderList.length; i++) {
        let obj = {
          checked:this.checkList.indexOf(this.tableHeaderList[i].name) != '-1' ? '1' : '0',
          label:this.tableHeaderList[i].label,
          name:this.tableHeaderList[i].name,
          required:this.tableHeaderList[i].required,
          sort:this.tableHeaderList[i].sort,
        }
        data.list.push(obj)
      }
      saveTableHeader(data).then(({success})=>{
        if (success) {
          loading.close();
          this.$message({ showClose: true, message: '配置成功!', type: 'success' })
          this.customShow = false
        }
      })
    }
  },
  filters: {
    payFilter(val, that) {
      if(val === 'CASH') return '现金'
      // if (val && that.dictData['payment-method']) return that.dictData['payment-method'][val] || val
       return that.dictData['payment-method'][val] || val
    },
    circlingFilter(val, that) {
      return that.dictData['preSale-state'][val] || val
    },
    discountsFilter(val, that) {
      return that.dictData['coupon-state'][val] || val
    },
    filtertrack(val) {
      switch (val) {
        case 'WECHAT_APPLET':
          return val = '进入微信小程序'
        case 'DOUYIN_APPLET':
          return val = '进入抖音小程序'
        case 'ORTHER':
          return val = '创建用户'
        case 'WECHAT_OFFICIAL':
          return val = '关注微信公众号'
      }
    },
    filterUserSource(val) {
      switch (val) {
        case 'APPLETS':
          return val = '小程序'
        case 'SSM':
          return val = '自助机'
        case 'APP':
          return val = 'APP'
        case 'WEB':
          return val = 'PC端'
        case 'PLATFORM':
          return val = '平台'
        case 'SMS':
          return val = '短信'
        case 'EMAIL':
          return val = '邮件'
        case 'WECHAT_OFFICIAL':
          return val = '微信公众号'
        case 'WECHAT_APPLET':
          return val = '微信小程序'
        case 'QQ':
          return val = 'QQ'
        case 'ALIPAY':
          return val = '支付宝'
        case 'DINGTALK':
          return val = '钉钉'
        case 'DOUYIN_PLATFORM':
          return val = '抖音'
        case 'XIAOHONGSHU':
          return val = '小红书'
        case 'PMS':
          return val = '酒店PMS'
      }
    }
  },
}
</script>

<style scoped lang="scss">
.srcm-userList-box {
  ::v-deep .el-tabs__header {
    margin: 0 0 31px;
    border-bottom: 1px solid #E3E4E5;

    .el-tabs__item {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2D2D2D;
    }

    .is-active {
      font-size: 18px;
      font-weight: bold;
      color: #2577E3;
    }
  }

  .group-box {
    ::v-deep .el-radio-button__inner {
      background: transparent;
    }
  }
}

.popover-box {
  ul {
    list-style: none;

    li:first-child {
      display: inline-block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      background: #f2f2f2;

      span:first-child {
        float: left;
        margin-left: 12px;
      }

      .el-button:last-child {
        float: right;
        margin-right: 12px;
      }
    }

    li:last-child {
      padding: 12px;
    }
  }
}

/deep/ .el-table th.gutter {
  /*
  * 解决element-ui 表格篡位的问题 👇
  */
  display: table-cell !important;
}

.import-user {
  display:flex;
  justify-content: space-around;
  width:440px;
  margin:0 auto;
  .import-box {
    cursor: pointer;
    text-align: center;
    font-size:19px;
    color:blue;
    font-weight:bold;
    img {
      width:120px;
      height:120px;
    }
  }
}
.el-icon-s-tools{
  cursor: pointer;
}
</style>
