import request from "@/api/service";

const api = {
   // 用户数据导入模板下载
   userDataTemplateDownload: '/member/userDataTemplateDownload',
   // 读取excel并标记会员数据
   markUserDataByExcel: '/member/markUserDataByExcel',
   // 导入用户数据
   importUserData: '/member/importUserData',
   // 查询自定义表头配置
   getTableHeader:'/system/table_header/get',
   // 保存自定义表头配置
   saveTableHeader:'/system/table_header/save'
};


export function userDataTemplateDownload() {
   return request({
      url: api.userDataTemplateDownload,
      method: 'GET',
      responseType: 'blob',
   })
}

export function markUserDataByExcel(param) {
   return request({
      url: api.markUserDataByExcel,
      method: 'POST',
      data: param,
   })
}

export function importUserData(param) {
   return request({
      url: api.importUserData,
      method: 'POST',
      data: param,
   })
}

export function getTableHeader(data) {
   return request({
      url: api.getTableHeader,
      method: 'POST',
      data,
   })
}

export function saveTableHeader(data) {
   return request({
      url: api.saveTableHeader,
      method: 'POST',
      data,
   })
}





